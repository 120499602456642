
    // @ is an alias to /src
    export default {
        name: 'Home',
        components: {
            
        },
        mounted() {
        }
    }
